import {Switch, Route, Redirect} from 'react-router';
import PasswordReset from "./PasswordReset";
import PasswordCreate from "./PasswordCreate";


import React, {Component} from "react";
import ProjectPanel from "./components/panels/ProjectPanel";
import {RouteWithLayout} from "./pages/layouts/RouteWithLayout";
import {ProjectsLayout} from "./pages/layouts/ProjectsLayout";
import {ProjectLayout} from "./pages/layouts/ProjectLayout";
import Dashboard2 from "./Dashboard2";
import {StaticDashboard} from "./pages/dashboard/StaticDashboard";
import {MinMaxIterationPanel} from "./components/panels/MinMaxIterationPanel";
import {IOScenarioOverview} from "./components/projects/IterationOverview";
import {MinMaxSettings} from "./components/settings/MinMaxSettings";
import {MovementTypeSettings} from "./components/settings/MovementTypeSettings";
import {DataMaturityPanel} from "./pages/DataMaturityPanel";
import {BOMsPanel} from "./pages/BOMsPanel";
import {PMCMPanel} from "./pages/PMCMPanel";
import {Commonality} from "./pages/Commonality";
import {DemoLayout} from "./pages/layouts/DemoLayout";
import {PicklesDemoDashboard} from "./pages/PicklesDemoDashboard";
import {DocumentAnalysis} from "./pages/DocumentAnalysis";
import DashboardCategoryLayout from "./pages/layouts/DashboardCategoryLayout";
import {LandingLayout} from "./pages/layouts/LandingLayout";
import {LandingPage} from "./pages/LandingPage";
import {InsuranceSettings} from "./components/settings/InsuranceSettings";
import {InsuranceItems} from "./pages/InsuranceItems";
import {ScopesSettings} from "./components/settings/ScopesSettings";
import {ScopePage} from "./components/scopes/ScopePage";
import {InsuranceMaterialPage} from "./pages/InsuranceMaterialPage";
import {WorkflowsPanel} from "./components/workflows/WorkflowsPanel";
import {WorkflowOverview} from "./components/workflows/WorkflowOverview";
import {MinMaxItem} from "./components/min-max-item/MinMaxItem";
import {WorkflowStatus} from "./components/workflows/statuses/WorkflowStatus";
import {DashboardV2} from "./pages/dashboard/dashboardV2";
import {DashboardManagement} from "./pages/dashboard/DashboardManagement";
import {ExceptionReporting} from "./components/exception-reporting/ExceptionReporting";
import {ReportOverview} from "./components/exception-reporting/ReportOverview";
import {WorkflowTodo} from "./components/workflows/WorkflowTodo";

export class AHRouter extends Component {

    render() {
        return (
            <div>
            <Switch>
                <Route path='/password-change*'
                       render={props => <PasswordReset {...props}/>}
                />

                <Route path='/password-create*'
                       render={props => <PasswordCreate {...props} />}
                />

                <RouteWithLayout layout={ProjectsLayout} exact path="/projects" component={ProjectPanel} />
                <RouteWithLayout layout={LandingLayout} exact path="/landing" component={LandingPage} />
                <RouteWithLayout layout={ProjectLayout} path="/projects/*/min-max/scenarios/*/overview" component={IOScenarioOverview} />
                <RouteWithLayout layout={ProjectLayout} path="/projects/*/overview" component={Dashboard2} />
                <RouteWithLayout layout={ProjectLayout} path="/projects/*/settings" component={MinMaxSettings} />
                <RouteWithLayout layout={ProjectLayout} path="/projects/*/ins-settings" component={InsuranceSettings} />
                <RouteWithLayout layout={ProjectLayout} path="/projects/*/movement-type" component={MovementTypeSettings} />
                <RouteWithLayout layout={ProjectLayout} path="/projects/*/scope/*" component={ScopePage} />
                <RouteWithLayout layout={ProjectLayout} path="/projects/*/scopes-settings" component={ScopesSettings} />
                <RouteWithLayout layout={ProjectLayout} exact path="/projects/*/workflow-settings" component={WorkflowsPanel} />
                <RouteWithLayout layout={ProjectLayout} exact path="/projects/*/workflow-todo" component={WorkflowTodo} />
                <RouteWithLayout layout={ProjectLayout} exact path="/projects/*/dashboard-v2*" component={DashboardV2} />
                <RouteWithLayout layout={ProjectLayout} exact path="/projects/*/dashboard-static-ptt" component={StaticDashboard} />
                <RouteWithLayout layout={ProjectLayout} exact path="/projects/*/dashboard-management" component={DashboardManagement} />
                <RouteWithLayout layout={ProjectLayout} path="/projects/*/workflow-settings/*" component={WorkflowOverview} />
                <RouteWithLayout layout={ProjectLayout} path="/projects/*/workflow-status*" component={WorkflowStatus} />
                <RouteWithLayout layout={ProjectLayout} path="/projects/*/ins-calculation" component={InsuranceItems} />
                <RouteWithLayout layout={ProjectLayout} exact path="/projects/*/exception-reporting" component={ExceptionReporting} />
                <RouteWithLayout layout={ProjectLayout} path="/projects/*/exception-reporting/*" component={ReportOverview} />
                <RouteWithLayout layout={ProjectLayout} path="/projects/*/min-max" component={MinMaxIterationPanel} />
                <RouteWithLayout layout={ProjectLayout} path="/projects/*/min-max-material-analysis" component={MinMaxItem} />
                <RouteWithLayout layout={ProjectLayout} path="/projects/*/ins-calculation-material-analysis" component={InsuranceMaterialPage} />
                <RouteWithLayout layout={ProjectLayout} path="/projects/*/boms" component={BOMsPanel} />
                <RouteWithLayout layout={ProjectLayout} path="/projects/*/pm-cm-ratio" component={PMCMPanel} />
                <RouteWithLayout layout={ProjectLayout} path="/projects/*/document-analysis" component={DocumentAnalysis} />
                <RouteWithLayout layout={ProjectLayout} path="/projects/*/commonality" component={Commonality} />
                <RouteWithLayout layout={DemoLayout} path="/projects/*/demo" component={PicklesDemoDashboard} />

                <RouteWithLayout layout={ProjectLayout}
                                 path="/projects/*/obsoletes"
                                 component={DashboardCategoryLayout}
                                 type={'obsoletes'}
                                 title={'Obsoletes'}
                />
                <RouteWithLayout layout={ProjectLayout}
                                 path="/projects/*/insurance"
                                 component={DashboardCategoryLayout}
                                 type={'insurance'}
                                 title={'Insurance'}
                />
                <RouteWithLayout layout={ProjectLayout}
                                 path="/projects/*/no-bom"
                                 component={DashboardCategoryLayout}
                                 type={'no-bom'}
                                 title={'No BOM'}
                />
                <RouteWithLayout layout={ProjectLayout}
                                 path="/projects/*/slow-moving"
                                 component={DashboardCategoryLayout}
                                 type={'slow-moving'}
                                 title={'Slow-Moving'}
                />

                <RouteWithLayout layout={ProjectLayout} path="/projects/*/data-maturity" component={DataMaturityPanel} />

                <Route exact path='/'
                       render={props => <Redirect to={'/projects'} {...props} />}
                />

                <Route path='/*'
                       render={props => <Redirect to={'/landing'} {...props} />}
                />
            </Switch>
            </div>
        );
    }

}
