import React from "react";
import {Col, Descriptions, Row, Tabs} from "antd";
import {MaterialBlock} from "../helpers/cards/MaterialBlock";
import {EditOutlined, HistoryOutlined, LinkOutlined} from "@ant-design/icons";
import {projectCurrency, ROP_MIN_LABEL} from "../../properties";
import {StandardTable} from "../helpers/TablePanel";
import {DataCard} from "../helpers/cards/Card";

const {TabPane} = Tabs;

export function MaterialCard({material, itemInfo, canEdit, onEdit, onHistoryOpen, leadTime}) {

    const getPFColumns = () => [
        {
            dataIndex: 'Count of 101\'s',
            title: 'Count of 101\'s',
            render: val => itemInfo.count_101 || 0

        }, {
            dataIndex: 'Total',
            title: 'Total Periods / Years',
            render: () => Math.round((itemInfo.count_101 || 0) / 7 * 100) / 100
        },
    ]

    const showValues = data => {
        if (Array.isArray(data)) {
            return data.join(', ')
        }

        return data
    }

    const getLeadTimeColumns = () => [
        {
            dataIndex: 'Purchasing Document',
            title: 'PO #'

        }, {
            dataIndex: 'Scheduled Quantity',
            title: 'Quantity'
        }, {
            dataIndex: 'Purchase Order Date',
            title: 'Purchase Order Date'
        }, {
            dataIndex: 'Delivery Date',
            title: 'Delivery Date',
            render: date => date.split(' ')[0]

        }, {
            dataIndex: 'Lead Time in Days',
            title: 'Planned Lead Time'

        }, {
            dataIndex: 'Actual Lead Time',
            title: 'Actual Lead Time'

        },
        {
            dataIndex: 'Actual Lead Time',
            title: 'Diff in days',
            render: (lt_actual, item) => Math.abs(lt_actual - item['Lead Time in Days'])
        },
        {
            dataIndex: 'Actual Lead Time',
            title: 'Diff in %',
            render: (lt_actual, item) => Math.round(Math.abs(lt_actual - item['Lead Time in Days']) / item['Lead Time in Days'] * 100)
        },
    ]

    return <DataCard title={<Row>
        <Col span={20}>
            <Row>
                <div style={{marginRight: 5}}>Material:</div>
                <MaterialBlock material={material}
                               children={<div>{material} - {itemInfo.description} <LinkOutlined/></div>}/>
                {(canEdit && !itemInfo.approved) && <EditOutlined style={{marginLeft: 5, marginTop: 5}}
                                                                  onClick={() => onEdit({editModal: true})}/>}

            </Row>
        </Col>
        <Col span={4}>
                                <span className='url-link float-right' style={{marginLeft: 10}}
                                      onClick={() => onHistoryOpen({historyModal: true})}>History <HistoryOutlined/></span>
        </Col>
    </Row>}>
        {itemInfo.approved &&
            <div style={{color: "green"}}>The {ROP_MIN_LABEL}/MAXES were overridden manually!</div>}
        {itemInfo['valid_for_optimisation'] === 0 &&
            <div style={{color: "red"}}>Material {material} is not included in any of
                existing scenarios!</div>}
        <Tabs defaultActiveKey={"1"} style={{marginTop: -15}}>
            <TabPane tab={'Material Data'} key={"1"}>

                <div>
                    <Descriptions title="" labelStyle={{fontWeight: 'bold'}} bordered size={'small'}>
                        <Descriptions.Item span={2} label="Type">{itemInfo['Type']}</Descriptions.Item>
                        <Descriptions.Item
                            label={`Price per unit (${projectCurrency})`}>{itemInfo.price}</Descriptions.Item>
                        <Descriptions.Item span={2} label="MRP Type">{showValues(itemInfo["MRP Type"])}</Descriptions.Item>
                        <Descriptions.Item label="Lead Time">{itemInfo.lt_days}</Descriptions.Item>
                        <Descriptions.Item span={3} label="Material Group">{showValues(itemInfo["mat_group"])}</Descriptions.Item>
                        <Descriptions.Item span={2} label={ROP_MIN_LABEL}>{itemInfo.rop}</Descriptions.Item>
                        <Descriptions.Item label="MAX">{itemInfo.max}</Descriptions.Item>
                        {itemInfo.approved && <Descriptions.Item span={2}
                                                                 label={`Approved ${ROP_MIN_LABEL}`}>{itemInfo.appr_min}
                        </Descriptions.Item>}
                        {itemInfo.approved && <Descriptions.Item
                            label="Approved MAX">{itemInfo.appr_max}
                        </Descriptions.Item>}

                    </Descriptions>
                </div>
            </TabPane>
            <TabPane tab={'Where-Used factors'} key={"3"}>
                <Descriptions labelStyle={{fontWeight: 'bold'}} bordered size={'small'}>
                    <Descriptions.Item label="Installed per Unit (Max)">{itemInfo.max_installed}</Descriptions.Item>
                    <Descriptions.Item span={2} label="Highest Criticality">{itemInfo.criticality}</Descriptions.Item>
                    <Descriptions.Item label="Total Quantity Installed">{itemInfo.installed}</Descriptions.Item>
                </Descriptions>
            </TabPane>
            <TabPane tab={`Lead Time Variation (${leadTime.length})`} key={"4"}>
                <StandardTable tableSize={'small'} data={leadTime} columns={getLeadTimeColumns()}/>
            </TabPane>

            <TabPane tab={`Purchasing Frequency`} key={"5"}>
                <StandardTable tableSize={'small'} data={[{test: ''}]} columns={getPFColumns()}/>
            </TabPane>
        </Tabs>
    </DataCard>
}
