import React, {useEffect, useState} from "react";
import {ModalWindow} from "../helpers/modals/ModalWindow";
import {Button, Checkbox, Col, Form, Input, message, Row, Select, Space, Steps} from "antd";
import {WorkflowVisualization} from "./WorkflowVisualization";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import {useForm} from "antd/es/form/Form";
import api from "../../api";
import Auth from "../../Auth";
import {CurrentUserContext} from "../../contexts/AuthContext";
import {projectCurrency, ROP_MIN_LABEL} from "../../properties";
import {labelMapper} from "../../helpers";
import {numberOptions} from "../helpers/AdvancedFIlter";

function AssignPeople({isModalVisible, onOk, onCancel, step, type_descr}) {

    const [form] = Form.useForm()
    const [positions, setPositions] = useState([])
    const [usersInPositions, setUsersInPositions] = useState({})

    useEffect(() => {
        if (isModalVisible) {
            form.setFieldsValue({
                description: `${type_descr} step ${step + 1}`
            })
            getPositions();
            getUsersInPositions('all')
        } else {
            form.resetFields()
        }
    }, [isModalVisible])

    const handleSubmit = () => {
        form.submit()
    }

    const getPositions = () => {
        api.get('/workflows/positions')
            .then(json => setPositions(json.data))
    }

    const getUsersInPositions = (position='all') => {
        api.get(`/workflows/positions/users?position=${position}`)
            .then(json => setUsersInPositions({...usersInPositions, [position]: json.data}))
    }

    const positionsOptions = () => positions.map(opt => {
        return {
            label: opt,
            value: opt
        }
    })

    const usersInPositionsOptions = (position) => usersInPositions[position] && usersInPositions[position].map(opt => {
        return {
            label: opt,
            value: opt
        }
    })

    const getEmailOptions = (key) => {
        let position = form.getFieldValue([`users`, key, `position`])

        if (position === undefined) {
            position = 'all'
        }
        return usersInPositionsOptions(position || 'all')
    }

    return <ModalWindow modalProps={{width: 600, }}  isModalVisible={isModalVisible} title={"Assign People"} onOk={handleSubmit} onCancel={onCancel}>
        <Form
            form={form}
            name="dynamic_form_nest_item"
            onFinish={onOk}
            style={{
                maxWidth: 700,
            }}
            autoComplete="off"
        >
            <Form.Item
                label={<b>Description</b>}
                name={"description"}
            >
                <Input placeholder="Description"  />
            </Form.Item>
            <Form.List name={'users'}>

                {(fields, { add, remove }) => (
                    <>

                        {fields.map(({ key, name, ...restField }) => (
                            <Space
                                key={key}
                                style={{
                                    display: 'flex',
                                    marginBottom: 8,
                                }}
                                align="baseline"
                            >
                                <Form.Item
                                    {...restField}
                                    label={<b>User</b>}
                                    name={[name, 'position']}
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Missing position',
                                        },
                                    ]}
                                >
                                    <Select style={{width: 180}} placeholder="Position"
                                            onChange={(value)=>{
                                                form.resetFields([[`users`, name, `user`]])
                                                getUsersInPositions(value)
                                            }}
                                            options={positionsOptions()}/>
                                </Form.Item>
                                <Form.Item
                                    {...restField}
                                    name={[name, 'user']}
                                    rules={[
                                        {
                                            required: false,
                                        },
                                    ]}
                                >
                                    <Select style={{width: 300}} placeholder="Email" options={getEmailOptions(name)}/>
                                </Form.Item>
                                <MinusCircleOutlined onClick={() => remove(name)} />
                            </Space>
                        ))}

                        <Form.Item>
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                Assign Person
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>
        </Form>
    </ModalWindow>

}


export function EditWorkflowModel({isModalVisible, onOk, onCancel, projectId}) {

    const [steps, setSteps] = useState( [])
    const [isAssignModalVisible, setIsAssignModalVisible] = useState(false)
    const [current, setCurrent] = useState('')
    const [isAutomatic, setIsAutomatic] = useState(false)
    const [itemTypes, setItemTypes] = useState([])

    useEffect(() => {
        setSteps([])
        setStep(0)
        getProposedValues()
        setIsAutomatic(false)
        form.setFieldsValue({
            workflow_name: `Workflow (${new Date().toLocaleDateString()})`,
            isAutomatic: false,
            max: ropMaxOptions[0]['value'],
            rop: ropMaxOptions[0]['value']
        })
    }, [isModalVisible])

    const getProposedValues = () => {
        api.get(`/projects/${projectId}/materials/attributes/values?attr=Type`)
            .then(json => {
                setItemTypes(json.data)
            })
    }

    const addStep = (values) => {
        let _steps = [...steps]
        if ('users' in values) {
            for (let user of values['users']) {
                user['user'] = user['user'] || null
                user['position'] = user['position'] || null
                user['notified'] = null
                user['status'] = null
            }
        }
        _steps.push({
            step: step,
            title: values['description'],
            step_type: current,
            users: values['users'] || []
        })
        setSteps(_steps)
        setStep(step + 1)
        setIsAssignModalVisible(false)
        setCurrent('')
    }

    const handleInput = values => {
        addStep(values)
    }

    const handleButtonClick = (mode) => {
        setIsAssignModalVisible(true)
        setCurrent(mode)
    }

    const handleSave = () => {
        if (steps.length === 0) {
            message.info('The workflow cannot be empty. Please add some steps to the workflow!')
            return;
        }
        let req = {
            'name': form.getFieldValue("workflow_name"),
            'isAutomatic': isAutomatic,
            'steps': steps
        }

        if (isAutomatic) {
            req['criteria'] = {
                'rop': form.getFieldValue('rop'),
                'max': form.getFieldValue('max')
            }

            if (form.getFieldValue('price_criteria') && form.getFieldValue('price_value')) {
                req['criteria']['price'] = {
                    'criteria': form.getFieldValue('price_criteria'),
                    'value': form.getFieldValue('price_value')
                }
            }

             if (form.getFieldValue('item_type')) {
                 req['criteria']['item_type'] = form.getFieldValue('item_type')
             }
        }

        api.post(`/projects/${projectId}/workflow/save`, {workflow: req})
            .then(json => {
                onOk()
            })
    }

    const ropMaxOptions = [
        {
            label: labelMapper('not_important'),
            value: 'not_important'
        },{
            label: labelMapper('gt'),
            value: 'gt'
        },{
            label: labelMapper('lt'),
            value: 'lt'
        },
    ]

    const typeOptions = itemTypes.map(_type => {
        return {
            label: _type,
            value: _type,
        }

    })

    const [form] = Form.useForm()
    const [step, setStep] = useState(0)

    return <ModalWindow isModalVisible={isModalVisible} title={"Edit Workflow"} onOk={handleSave} onCancel={onCancel}>
        <AssignPeople type_descr={current} step={step} isModalVisible={isAssignModalVisible} onOk={handleInput} onCancel={() => setIsAssignModalVisible(false)} />
        <Form form={form}>
        <Form.Item label={'Workflow Name'} name={'workflow_name'}>
            <Input  />
        </Form.Item>
            <Form.Item
                label={<b>Should be automatically assigned</b>}
                name={"isAutomatic"}
            >
                <Checkbox checked={isAutomatic} onChange={(event) => setIsAutomatic(event.target.checked)} />
            </Form.Item>
            {isAutomatic && <>
                <Form.Item
                    required
                    label={<b>{ROP_MIN_LABEL}</b>}
                    name={"rop"}
                >
                    <Select options={ropMaxOptions} />
                </Form.Item>

                <Form.Item
                    required
                    label={<b>MAX</b>}
                    name={"max"}
                >
                    <Select options={ropMaxOptions} />
                </Form.Item>

                <Space>
                    <Form.Item
                        label={<b>Price ({projectCurrency})</b>}
                        name={"price_criteria"}
                    >
                        <Select options={numberOptions} style={{width: 150}} />
                    </Form.Item>
                    <Form.Item
                        name={"price_value"}
                    >
                        <Input style={{width: 300, marginLeft: 15}}  />
                    </Form.Item>
                </Space>

                <Form.Item
                    label={<b>Item Type</b>}
                    name={"item_type"}
                >
                    <Select options={typeOptions} />
                </Form.Item>
            </>}
        </Form>

        <Row>
            <Col span={12}>
                <WorkflowVisualization steps={steps} />
            </Col>
            <Col span={12}>
                <div>
                <Button style={{marginRight: 10}} onClick={() => handleButtonClick('approve')}>Add Approval Step</Button>
                </div>
                <Button style={{marginTop: 10}} onClick={() => handleButtonClick('acknowledge')}>Add Acknowledge Step</Button>
            </Col>
        </Row>


    </ModalWindow>

}
