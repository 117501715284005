import React from "react";
import { Line } from 'react-chartjs-2';
import {properties} from "../../properties";


export function ForecastingGraph ({data, labels, colors=[], legendFields, height, titleLabels}) {

    const options = {
        scales: {
            y: {
                ticks: {
                    min: 0,
                    stepSize: 1
                }
            },
        },
        annotation: {
            annotations: [
                {
                    drawTime: "afterDatasetsDraw",
                    type: "line",
                    mode: "vertical",
                    scaleID: "x-axis-0",
                    value: 5,
                    borderWidth: 5,
                    borderColor: "red",
                    label: {
                        content: "Current Month",
                        enabled: true,
                        position: "top"
                    }
                }
            ]
        },
    };

    const getChartData = () => {
        const chartData =  {
            labels: data.map(s => s['date']),
            datasets: labels.map((v, i) => {
                return {
                    label: legendFields[i],
                    data: data.map(s => s[v] !== '' ? s[v] : null),
                    borderColor: colors[i] || 'black',
                    backgroundColor: colors[i] || 'black',
                    fill: false,
                    stepped: true,
                }
            })
        };

        chartData['datasets'].push({
            label: 'Zero point',
            data: data.map(s => 0),
            borderColor: 'yellow',
            backgroundColor: '#FFFFE0',
            fill: false,
        })

        return chartData
    }

    return (
        <Line style={{height: height}} data={getChartData()} options={options} />
    )

}
